import { defineStore } from 'pinia';
import { PublisherStatus } from '~/interfaces/publisher';
import { ElMessageBox, ElNotification } from 'element-plus';
import { ApiKey } from "~/common/API/baseApi";

const runtimeConfig = useRuntimeConfig();


let authKeyLink = '/user/telegram/bot/generate-auth-key';
// let checkTgAuthLink = '/user/auth/telegram/get-access-token';
let checkTgAuthLink = '/user/telegram/bot/check-auth';
// let checkTgAuthLink = '/user/bot/publisher/login';
let emailAuthLink = '/user/login';

let timeoutValueToCheckTg = 60000;
let intervalValueToCheckTg = 3000;

export const usePublisherStore = defineStore('publisher', () => {
  let id: Ref<string> = ref('');
  let name: Ref<string> = ref('');
  let status = ref<PublisherStatus>(PublisherStatus.OK);
  let telegram: Ref<string> = ref('');
  let email: Ref<string> = ref('');
  let emailConfirmed: Ref<boolean> = ref(false);
  let authKey: Ref<string> = ref('');
  let authorized: Ref<string> = ref('');

  let intervalIdToTgCheck: Ref<number> =  ref(0);
  let timeoutIdToTgCheck: Ref<number> =  ref(0);

  let loginDialogVisible: Ref<boolean> = ref(false);

  // Взял из стока, зачем пока не понятно
  function setStatus(data: PublisherStatus) {
    status.value = data;
  }

  function setEmail(data: string) {
    email.value = data;
  }

  function getUserChannels() {
    return [];
  }

  function resetAuthKey() {
    authKey.value = '';
    authorized.value = '';
    id.value = '';
    setLS();
  } // resetAuthKey

  function setAuthorized(key: string, auth: string, id_v: string) {
    authKey.value = key;
    authorized.value = auth;
    id.value = id_v;
    setLS();
  } // setAuthorized

  function setLS() {
    localStorage.setItem('usePublisherStore__authKey', authKey.value);
    localStorage.setItem('usePublisherStore__authorized', authorized.value);
    localStorage.setItem('usePublisherStore__id', id.value);
    localStorage.setItem('usePublisherStore__email', email.value);
    localStorage.setItem('usePublisherStore__emailConfirmed', emailConfirmed.value);
    localStorage.setItem('usePublisherStore__telegram', telegram.value);
    useCookie('authorized').value = authorized.value;
  } // setLS

  function requestAuthKey() {
    const usePublisherStore__authorized = localStorage.getItem('usePublisherStore__authorized');
    if (usePublisherStore__authorized) {
      authorized.value = usePublisherStore__authorized
    }
    const usePublisherStore__authKey = localStorage.getItem('usePublisherStore__authKey');
    if (usePublisherStore__authKey) {
      authKey.value = usePublisherStore__authKey
    }
    const usePublisherStore__telegram = localStorage.getItem('usePublisherStore__telegram');
    if (usePublisherStore__telegram) {
      telegram.value = usePublisherStore__telegram
    }
    const usePublisherStore__email = localStorage.getItem('usePublisherStore__email');
    if (usePublisherStore__email) {
      email.value = usePublisherStore__email
    }
    const usePublisherStore__emailConfirmed = localStorage.getItem('usePublisherStore__emailConfirmed');
    if (usePublisherStore__emailConfirmed) {
      emailConfirmed.value = usePublisherStore__emailConfirmed === 'true'
    }
    const usePublisherStore__id = localStorage.getItem('usePublisherStore__id');
    if (usePublisherStore__id) {
      id.value = usePublisherStore__id
    }
    if (authorized.value) {
      return;
    }
    if (authKey.value && authKey.value !== 'undefined') {
      return;
    }
    $fetch(authKeyLink, {
      baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
      method: 'POST',
      onRequest({ request, options }) {
        // Set the request headers
        // options.headers = options.headers || {}
        // options.headers.authorization = '...'
      },
      onRequestError({ request, options, error }) {
        // Handle the request errors
      },
      onResponse({ request, response, options }) {
        // Process the response data
        // TODO: remove console.log
        console.log(555, response);
        authKey.value = response._data.authKey;
        localStorage.setItem('usePublisherStore__authKey', authKey.value);
      },
      onResponseError({ request, response, options }) {
        // Handle the response errors
      }
    })
  } // requestAuthKey

  async function startTelegramAuthorize(): Promise<boolean> {
    try {
      await ElMessageBox.confirm(
        'Вход через Telegram',
        'Перейдите в Telegram и нажмите start в боте',
        {
          showCancelButton: false,
          confirmButtonText: 'OK',
        }
      );
      const url = `${useRuntimeConfig().public.RESOLVE_TELEGRAM_AUTHORIZE_PREFIX_PUBLISHER}${authKey.value}`;
      console.log('check url for telegram bot login:');
      console.log(url);
      window.open(url, '_blank');
      loginDialogVisible.value = false;
      return await checkTelegramAuthorize();
    } catch {
      return false;
    }
  } // startTelegramAuthorize

  function checkTelegramAuthorize():Promise<boolean> {
    return new Promise((resolve) => {
      // Очищаем текущие интервалы и таймауты перед запуском
      clearInterval(intervalIdToTgCheck.value);
      clearTimeout(timeoutIdToTgCheck.value);

      // Устанавливаем таймаут для завершения интервала через указанное время
      timeoutIdToTgCheck.value = window.setTimeout(() => {
        clearInterval(intervalIdToTgCheck.value);
        resolve(false); // Возвращаем false, если истекло время таймаута
      }, timeoutValueToCheckTg);

      // Устанавливаем интервал для проверки авторизации
      intervalIdToTgCheck.value = window.setInterval(() => {
        if (authorized.value && id.value && telegram.value) {
          clearInterval(intervalIdToTgCheck.value);
          clearTimeout(timeoutIdToTgCheck.value);
          resolve(true); // Возвращаем true, если данные успешно получены
          return;
        }

        // Выполняем запрос для проверки статуса авторизации
        $fetch(checkTgAuthLink, {
          baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
          method: 'GET',
          query: {
            authKey: authKey.value,
          },
          onRequest({ request, options }) {},
          onRequestError({ request, options, error }) {},
          onResponse({ request, response, options }) {
            console.log(response);
            if (response.status === 500) {
              ElNotification({
                title: 'Ошибка авторизации',
                message: 'попробуйте чуть позже или свяжитесь с администратором...',
                type: 'warning',
              });
              clearInterval(intervalIdToTgCheck.value);
              clearTimeout(timeoutIdToTgCheck.value);
            }
            // Если доступен токен авторизации, сохраняем его и останавливаем проверку
            if (response._data.accessToken && response._data.id) {
              authorized.value = response._data.accessToken;
              id.value = response._data.id;
              telegram.value = response._data.telegram;
              console.log(response);
              ApiKey.set(response._data.accessToken);
              if (response._data.email) {
                email.value = response._data.email;
                emailConfirmed.value = true;
              }
              ElNotification({
                title: 'Успешная авторизация через Telegram',
                message: 'Спасибо!',
                type: 'success',
              });
              setLS();
              clearInterval(intervalIdToTgCheck.value);
              clearTimeout(timeoutIdToTgCheck.value);
              status.value = PublisherStatus.AUTHORIZED_IN_BOT;
              resolve(true); // Возвращаем true при успешной авторизации
            } else {
              ElNotification({
                title: 'Ожидание авторизации',
                message: 'Ждём подтверждения от бота...',
                type: 'warning',
              });
            }
          },
          onResponseError({ request, response, options }) {},
        });
      }, intervalValueToCheckTg);
    });
  };

  function startEmailAuthorize({emaill, passwords}: {emaill: string, passwords: string}) {
    return new Promise((resolve) => {
      $fetch(emailAuthLink, {
        baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
        method: 'POST',
        body: {
          username: emaill,
          password: passwords,
        },
        onResponseError({ request, response, options }) {
          resolve(false);
          ElNotification({
            title: 'Ошибка авторизации',
            message: 'попробуйте чуть позже или свяжитесь с администратором...',
            type: 'warning',
          });
        },
        onResponse({ request, response, options }) {  
          console.log(response, email);
          if (response._data.accessToken && response._data.id) {
            authorized.value = response._data.accessToken;
            ApiKey.set(response._data.accessToken);
            id.value = response._data.id;
            email.value = emaill.toString();
            emailConfirmed.value = true;
            ElNotification({
              title: 'Успешная авторизация через Email',
              message: 'Спасибо!', 
              type: 'success',
            });
            setLS();
            status.value = PublisherStatus.VERIFICATED_EMAIL;
            resolve(true); // Возвращаем true при успешной авторизации
          }
        }
      })
    })
  }

  function logout() {
    resetAuthKey();
  }

  return {
    id,
    name,
    status,
    telegram,
    email,
    emailConfirmed,
    authKey,
    authorized,
    loginDialogVisible,
    intervalIdToTgCheck,
    timeoutIdToTgCheck,
    setLS,
    requestAuthKey,
    resetAuthKey,
    setAuthorized,
    startTelegramAuthorize,
    checkTelegramAuthorize,
    setStatus,
    setEmail,
    getUserChannels,
    startEmailAuthorize,
    logout
  }
});
